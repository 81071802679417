.bar-progress {
  fill: rgb(64, 132, 88) !important;
}

.today-highlight {
  fill: red !important;
}

.bar-label {
  fill: black !important;
  font-size: 14px !important;
  font-weight: normal !important;
}

.big {
  fill: black !important;
}

.form-check-label {
  color: black !important;
}

.popup {
  width: 5rem;
  padding: 0.5rem;
  height: 2.2rem;
}

@font-face {
  font-family: "Market_Deco";
  src: local("Market_Deco"), url("./fonts/Market_Deco.ttf") format("truetype");
  font-weight: bold;
}

div.no-arrow .dropdown-toggle::after {
  display: none !important; 
}

body {
  /* background-color: black !important; */
  /* background-color: rgb(34, 37, 41); */
  background-color: rgb(45, 48, 52);
  padding: 0;
}

h1,
h2,
h3,
h4,
h5, h6,
p,
label {
  color: white !important;
}

a {
  color: lightskyblue;
}

.branding {
  font-family: "Market_Deco";
}

.header {
  margin-bottom: 1.25rem;
  padding: 1rem;
}

.nav-btn {
  margin-top: 0.85rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.torque-logo {
  /* width: 3rem; */
  /* height: 3rem; */
  height: 3rem;
  width: auto;
}

.black-bg {
  background-color: black;
}

.dark-gray-bg {
  background-color: rgb(24, 26, 27);
}

.med-gray-bg {
  background-color: rgb(34, 37, 41);
}

.lite-gray-bg {
  background-color: rgb(45, 48, 52);
}

.dropdown-toggle {
  /* width: 10rem; */
  width: 100%;
}

.invisible {
  opacity: 0%;
}

.details-view {
  height: 13rem;
}

.string-list {
  background-color: white;
  border-radius: 0.5rem;
}

.string-list-table {
  height: 9.25rem;
  overflow: scroll;
  display: block;
}

.string-list-row {
}

.string-list-search {
  margin-bottom: .5em;
  width: 96%;
}

.npm-version {
  margin-right: 1em;
}

.not-found {
  display: flex;
  justify-content: center;
}

.not-found-text {
  position: absolute;
}

.not-found-text-bottom {
  position: absolute;
  bottom: 7em;
}

.string-table {
  display: flex;
  flex-direction: column;
}

.string-list-cell {
  display: flex;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: .2em;
  margin-bottom: .2em;
}

.form-control:focus {
  box-shadow: none;
}

.string-list-add-button {
  margin: .5em;
  margin-left: 0em;
  margin-top: 1em;
}

.string-list-search-input {
  margin: 1em;
  margin-right: 0em;
  margin-bottom: .5em;
}

.sort-btn {
  padding: 0rem;
  padding-top: .1rem;
  padding-bottom: 1.3rem;
  padding-left: .2rem;
  padding-right: .2rem;
  margin-bottom: .15rem;
  margin-top: .19rem;
  height: 1.25rem;
  width: 1.5rem;
}

.col-head {
  width: fit-content;
}

.date-col {
  max-width: 6rem;
}

.ete-btn {
  height: 1.5rem;
  width: 1.5rem;
  padding: 0rem;
  padding-top: .1rem;
  padding-bottom: 1.3rem;
  padding-left: .2rem;
  padding-right: .2rem;
  margin-top: .19rem;
}

.flt-left {
  width: 70%;
  float: left;
}

.ete-left {
  width: 85%;
  float: left;
}

.flt-right {
  float: right;
}

.ete-right {
  float: right;
}


.text-tbl-entry {
  margin-top: 2px;
}

.ete-plhldr {
  float: right;
  width: 1.5rem;
}

.gaant-head {
  padding-top: .9rem;
  padding-bottom: .2rem;
}

.p-tip {
  margin-top: .14rem;
}

.overtime {
  background-color: #dc3545;
  border-color: #dc3545;
}

.tooltip-bg {
  padding: .5rem;
  background-color: white;
}

.tooltip-text {
  color: black !important;

}